<template>
  <ul class="foot-print-wapper">
    <li class="foot-print-li" v-for="(item, index) in realData" :key="index">
      <div class="date">{{ item.date }}</div>
      <ul class="good-ul" v-for="(itm, i) in item.data" :key="i">
        <product-card
          v-if="itm.type == 'product'"
          :list="{ data: [itm] }"
          name="productdetail"
          tid="0"
        ></product-card>
        <product-card
          v-if="itm.type == 'bncc'"
          :list="{ data: [itm] }"
          name="bnccdetail"
          tid="3"
        ></product-card>
        <li class="good-li topic-li" v-if="itm.type == 'topic'">
          <router-link :to="itm.url">
            <div class="good-name">{{ itm.title }}</div>
            <div class="good-no">{{ itm.summary }}</div>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
  <empty
    text="足迹"
    :showLive="false"
    v-if="realData && !realData.length"
  ></empty>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import productCard from "@/components/common/productCard.vue";
import useFootPrint from "@/hooks/useFootPrint";
import dateFormat from "@/utils/dateFormat";
import Empty from "@/components/common/Empty.vue";
export default defineComponent({
  name: "FootPrint",
  components: {
    productCard,
    Empty,
  },
  setup() {
    const { getFootItem, footQueue } = useFootPrint();
    getFootItem();
    const realData = [];
    const data = footQueue.value();
    for (let index = data.length - 1; index >= 0; index--) {
      const item: any = data[index];
      if (realData.length) {
        if (realData[realData.length - 1].date == item.addtime) {
          realData[realData.length - 1].data.push(item);
        } else {
          realData.push({
            date: item.addtime,
            data: [item],
          });
        }
      } else {
        realData.push({
          date: item.addtime,
          data: [item],
        });
      }
    }

    return {
      realData,
      dateFormat,
    };
  },
});
</script>


<style lang="scss" scoped>
main {
  padding-bottom: 110px;
}

.date {
  background-color: #f4f4f4;
  line-height: 32.5px;
  box-sizing: border-box;
  padding: 0 10px;
  color: #999999;
  font-size: 13.988px;
}

.topic-li {
  padding: 0 10px;
  border-bottom: 1px solid #f4f4f4;
}

.good-li {
  background-color: #fff;
  height: 75px;
  padding-left: 98px;
  box-sizing: border-box;
  position: relative;
  padding-top: 10px;
}
.topic-li {
  padding-left: 10px;
}

.good-pic {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 78px;
  height: 78px;
}

.good-name {
  font-size: 15px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  padding-left: 10px;
  box-sizing: border-box;
}

.good-name::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 5px;
  height: 5px;
  background: #7f7f7f;
  border-radius: 50%;
}

.good-no,
.good-no p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f29773;
  font-size: 14px;
  margin-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
</style>