<template>
  <div class="empty">
    <img
      src="@/assets/img/icon36.png"
      class="focus_img"
    />
    <div>
      暂无<span>{{ text }}</span
      >信息 <span v-if="showLive">
        ，如有疑问，请<a
        href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
        >联系客服</a
      >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Empty",
  props: {
    text: {
      type: String,
      default: "",
    },
    showLive: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.empty {
  padding-top: 160px;
  text-align: center;
  padding-bottom: 50px;

  color: #9f9f9f;
  font-size: 13px;
}
.empty img {
  width: 132px;
  height: 104px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.empty a {
  font-size: 13px;
  color: #b9135a;
}
</style>
